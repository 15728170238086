import React from 'react'
import { findTranslation } from '../../../common/translation'
import numeral from 'numeral'
import makeProductDataPresentable from '../../../common/make-product-data-presentable'
import getRelevantWarehouses from '../../../common/get-relevant-warehouses'
import warehouses from '../../../constants/warehouses'
import { addCartItem } from '../../cart/actions'
import classnames from 'classnames'
import imageToSrcset from '../../../transforms/image-to-srcset'
import AddToCartButton from './add-to-cart-button'
import Availability from '../../common/availability'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'CompactListItem',

  getInitialState () {
    return {
      hover: false,
      quantity: 0
    }
  },

  handleMouseLeave () {
    this.setState({
      hover: false
    })
  },

  calculateCustomMarkup (dealerPrice, dealer) {
    var markup
    if (dealerPrice >= 2000) markup = dealer.customMarkup[9] || 30;
    else if (dealerPrice >= 1000) markup = dealer.customMarkup[8] || 35;
    else if (dealerPrice >= 500) markup = dealer.customMarkup[7] || 40;
    else if (dealerPrice >= 300) markup = dealer.customMarkup[6] || 50;
    else if (dealerPrice >= 100) markup = dealer.customMarkup[5] || 65;
    else if (dealerPrice >= 50) markup = dealer.customMarkup[4] || 75;
    else if (dealerPrice >= 10) markup = dealer.customMarkup[3] || 80;
    else if (dealerPrice >= 5) markup = dealer.customMarkup[2] || 85;
    else if (dealerPrice >= 1) markup = dealer.customMarkup[1] || 90;
    else if (dealerPrice >= 0) markup = dealer.customMarkup[0] || 100;
    var retailPrice = (((parseFloat(markup) + 100) / 100) * dealerPrice)
    retailPrice = numeral(retailPrice).format('0,0.00')
    return retailPrice
  },

  handleMouseEnter () {
    this.setState({
      hover: true
    })
  },

  handleQuantityChange (quantity) {
    this.setState({ quantity })
  },

  render () {
    const session = this.props.session
    const isOnline = this.props.isOnline
    const isRetailMode = this.props.isRetailMode
    const language = this.props.language
    const product = this.props.product.attributes
    const inventory = product.inventory || []
    const warehouseCode = this.props.warehouseCode
    const userRoles = this.props.userRoles
    const clearanceQty = this.props.product.attributes.categoryIds.indexOf("ffefca7d-a309-4a7c-8b93-34c7d76a9712") > -1?this.props.product.attributes.clearanceQty:0
    const isDiscontinued = product.isDiscontinued || false
    const { subscribeToProductUpdates } = this.props
    var hasInv = false;
  // Identify if product is new by checking if it is in the new category
  const isNewProduct = this.props.product.attributes.categoryIds?.includes('9daf56fb-e712-4daf-81bb-e28d09377a0d')

  // Identify if product is on clearance by checking if it is in the clearance category
  const isClearanceProduct = this.props.product.attributes.categoryIds?.includes('ffefca7d-a309-4a7c-8b93-34c7d76a9712')

  // Identify if product is a scratch and dent product by checking if it is in the scratch and dent category
  const isScratchAndDentProduct = this.props.product.attributes.categoryIds?.includes('19818b31-8ff8-4a31-a4c3-bdbaf8524b66')

    if (inventory === undefined || inventory.length == 0) {hasInv = false}
    else {
      hasInv = !(inventory[0].quantity <= 0 && inventory[1].quantity <= 0 && inventory[2].quantity <= 0 && inventory[3].quantity <= 0)
    }
    let relevantWarehouses
    if (this.props.product.attributes.categoryIds.indexOf("ffefca7d-a309-4a7c-8b93-34c7d76a9712") > -1) {
      relevantWarehouses = inventory
    } else {
      relevantWarehouses = getRelevantWarehouses({
        primaryWarehouseCode: warehouseCode,
        quantity: this.state.quantity || 0,
        inventory,
        warehouses,
        isPrivileged
      })
    }

    product.images = product.images || []
    const imageSourceset = imageToSrcset(product.images && product.images[0])

    const {
      title,
      shortDescription,
      manufacturerName
    } = makeProductDataPresentable({
      title: findTranslation(product.title, language.code),
      shortDescription: findTranslation(
        product.shortDescription,
        language.code
      ),
      manufacturerName: product.manufacturerName,
      manufacturerPartNumber: product.manufacturerPartNumber
    })

    const dealerPrice =
      product.dealerPrice && numeral(product.dealerPrice).format('0,0.00')
    const FXSurcharge =
      product.FXSurcharge && numeral(product.FXSurcharge).format('0,0.00')
    const clearancePrice =
      product.clearancePrice && numeral(product.clearancePrice).format('0,0.00')
    const flyerPrice =
      product.flyerPrice && numeral(product.flyerPrice).format('0,0.00')
    var retailPrice
    if (product.FXSurcharge > 0) {
      retailPrice =
        product.retailPrice && numeral(product.retailPrice + product.FXSurcharge).format('0,0.00')
    } else { 
      retailPrice =
        product.retailPrice && numeral(product.retailPrice).format('0,0.00')
    }
    const unitOfMeasure =
      dealerPrice && product.unitOfMeasure && `(${product.unitOfMeasure})`

    const rowClasses = classnames({
      'atlas-compact-item-hover': this.state.hover
    })
    const isPrivileged = Boolean(
      (userRoles &&
        (userRoles.includes('Administrator') || userRoles.includes('Sales'))) ||
        clearancePrice
    )

    return (
      <tr
        className={rowClasses}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <td style={{ textAlign: 'center', height: '60px', position: 'relative' }}>
        {
          isNewProduct && <div className='new-badge small'/>
        }
        {
          isClearanceProduct && <div className='clearance-badge small'>
          </div>
        }
        {
          isScratchAndDentProduct && <div className='scratch-and-dent-badge small'/>
        }
          {imageSourceset &&
            <img
              sizes="2vw"
              srcSet={imageSourceset}
              style={{ height: 60 }}
              alt="Product"
            />}
        </td>
        <td>
          <a href={`/?q=${encodeURIComponent(product.distributorPartNumber)}`}>
            {product.distributorPartNumber}
          </a>
        </td>
        <td>
          <a href={`/?q=${encodeURIComponent(product.distributorPartNumber)}`}>
            <div style={{ fontSize: '0.85rem' }}>
            {manufacturerName}
          </div>
          <div style={{ fontSize: '0.85rem' }}>
            {product.manufacturerPartNumber &&
              <span>
                #{product.manufacturerPartNumber}
              </span>}
          </div></a>
        </td>
        <td>
          <a href={`/?q=${encodeURIComponent(product.distributorPartNumber)}`}>
                    
          <div style={{ fontWeight: 'bold' }}>{title || shortDescription}</div></a>
        </td>
        <td>
          <a href={`/?q=${encodeURIComponent(product.distributorPartNumber)}`}>  
          <div style={{ fontWeight: 'bold' }}>{product.cataloguePageNum || '' }</div></a>
        </td>        
        {(!isRetailMode && !(isDiscontinued && !hasInv)) && 
          <td>
            {!clearancePrice &&
              !flyerPrice &&
              <span>
                <span className="atlas-dealer-price">{dealerPrice || ''}</span>
                {' '}
                {unitOfMeasure || ''}
              </span>}
            {(clearancePrice || flyerPrice || '') &&
              <div>
                <div>
                  <span className="atlas-clearance-price">
                    {clearancePrice || flyerPrice || ''}
                  </span>
                  {' '}
                  {unitOfMeasure || ''}
                </div>
                <div className="atlas-regular-price">
                  {dealerPrice || ''}
                </div>
              </div>}
          </td>}

          {(!isRetailMode && !(isDiscontinued && !hasInv)) && 
          <td style={{ color: 'blue'}}>
          {(FXSurcharge && FXSurcharge > 0) && FXSurcharge} 
          {(!FXSurcharge || FXSurcharge == 0) && ""} 
          </td>}

        {(!isRetailMode && (isDiscontinued && !hasInv)) && 
          <td></td>
        }

        {!(isDiscontinued && !hasInv) &&   
          <td>
            <span className="atlas-retail-price">{session.dealer.usesCustomPrices ? this.calculateCustomMarkup(dealerPrice, session.dealer) : (retailPrice || '')}</span>
          </td>
        }
        
        {(isDiscontinued && !hasInv) &&   
          <td>
          </td>
        }
        {(isDiscontinued && !hasInv) &&   
          <td>
          </td>
        }

        <td>
          <Availability
            compact
            isPrivileged={isPrivileged}
            language={language}
            primaryWarehouseCode={warehouseCode}
            isCumulativeSummary={Boolean(clearancePrice)}
            quantity={this.state.quantity}
            userRoles={userRoles}
            warehouses={relevantWarehouses}
            clearanceQty={clearanceQty}
            subscribeToProductUpdates={subscribeToProductUpdates}
            productId={product.distributorPartNumber || ''}
            showNotifyMe={true}
          />
        </td>
        <td style={{ lineHeight: '1rem' }}>
          {(dealerPrice || '') &&
            isOnline &&
            <AddToCartButton
              addCartItem={addCartItem.bind(null, this.props.product)}
              onQuantityChange={this.handleQuantityChange}
            />}
        </td>
        {(isDiscontinued && !hasInv) && <div style={{position:"absolute", left:0, width:"100%", color:"#B6D2D2" , opacity:"0.75", fontSize:36, textAlign:"center",marginTop:"20px", transform: "rotate(-5deg)"}}>No Longer Available</div>}
      </tr>
    )
  }
})
