import { legacy_createStore as createStore } from 'redux'
import reducers from './reducers/index'

const store = createStore(reducers)

window.atlas = store

export const dispatch = store.dispatch
export const subscribe = store.subscribe
export const getState = store.getState
