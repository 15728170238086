import React from 'react'

export default function FlipPDF () {
        return (
            <div style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '84px', width:'100%' }}>
                <h1 style={{ textAlign: 'center' }}>2025 Product Catalogue in Flip PDF   |    Catalogue de produits 2025 en Flip PDF</h1>
                    <div style={{ textAlign:'center' }}>
                        <div style={{ display: 'inline-block', margin: 'auto', maxWidth:"500px" }}>
                            <a href="https://books.atlastrailer.com/books/yqbn/" target="_blank">
                                <img src="https://rvcatalogue.s3.us-east-1.amazonaws.com/cover2025/WEB+2025+Atlas+Product+Catalogue+Cover+ENGLISH.jpg" style={{ display: 'inline-block', margin: 'auto', maxWidth:"500px" }} alt=''/>
                            </a>
                            <h1 style={{ textAlign: 'center' }}>English</h1>
                        </div>
                        <div style={{ display: 'inline-block', margin: 'auto', width:"100px" }}></div>
                        <div style={{ display: 'inline-block', margin: 'auto', maxWidth:"500px" }}>
                            <a href="https://books.atlastrailer.com/books/nmmr/" target="_blank">
                                <img src="https://rvcatalogue.s3.us-east-1.amazonaws.com/cover2025/WEB+2025+Atlas+Product+Catalogue+Cover+FRENCH.jpg" style={{ display: 'inline-block', margin: 'auto', maxWidth:"500px" }} alt='' />
                            </a>
                            <h1 style={{ textAlign: 'center' }}>Français</h1> 

                        </div>
                    </div>
            </div>
        )
}
